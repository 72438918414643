import light from './light.js';
import lightHighContrast from './light_high_contrast.js';
import lightColorblind from './light_colorblind.js';
import lightTritanopia from './light_tritanopia.js';
import dark from './dark.js';
import darkDimmed from './dark_dimmed.js';
import darkHighContrast from './dark_high_contrast.js';
import darkColorblind from './dark_colorblind.js';
import darkTritanopia from './dark_tritanopia.js';

const colors = {
  light,
  // eslint-disable-next-line camelcase
  light_high_contrast: lightHighContrast,
  // eslint-disable-next-line camelcase
  light_colorblind: lightColorblind,
  // eslint-disable-next-line camelcase
  light_tritanopia: lightTritanopia,
  dark,
  dark_dimmed: darkDimmed,
  // eslint-disable-next-line camelcase
  dark_high_contrast: darkHighContrast,
  // eslint-disable-next-line camelcase
  dark_colorblind: darkColorblind,
  // eslint-disable-next-line camelcase
  dark_tritanopia: darkTritanopia
};

export { colors };
